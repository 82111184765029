export const mockGuestbook = [
  {
    guestbookCode: 1,
    guestCode: 21,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '지영준',
    guestbookContents: '방명록 내용내용 내용내용 들리고 갑니다 ㅎㅎ',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 2,
    guestCode: 1,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '홍길동',
    guestbookContents: '2222222222들리고갑니다222',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 3,
    guestCode: 2,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김코딩',
    guestbookContents: '333333333333333333',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 4,
    guestCode: 21,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '지영준',
    guestbookContents: '반갑습니다!!',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 5,
    guestCode: 2,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김코딩',
    guestbookContents: '333333333333333333',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 6,
    guestCode: 3,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김철수',
    guestbookContents: '반갑습니다!!',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 7,
    guestCode: 2,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김코딩',
    guestbookContents: '333333333333333333',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 8,
    guestCode: 3,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김철수',
    guestbookContents: '반갑습니다!!',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 9,
    guestCode: 2,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김코딩',
    guestbookContents: '333333333333333333',
    guestbookCreateDate: '2022-07-29',
  },
  {
    guestbookCode: 10,
    guestCode: 3,
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    nickname: '김철수',
    guestbookContents: '반갑습니다!!',
    guestbookCreateDate: '2022-07-29',
  },
];
