export const mockCommentList = [
  {
    userCode: 21,
    nickname: '지영준',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents: '가나다라마바사 댓글테스트.',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 18,
    nickname: '댓글러2',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents:
      '테스트 댓글입니다긴댓글을 한번 적어보겠습니다 하나둘셋넷다섯여섯 일곱여덟 아홉열 ㅎ열하나 열둘.',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 21,
    nickname: '지영준',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents: '댓글댓글 대앳글 댓글댓글댓글댓글댓글',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 21,
    nickname: '지영준',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents: '댓글댓글 대앳글 댓글댓글댓글댓글댓글....',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 16,
    nickname: '홍길동',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents:
      '길동길동 홍길동 고길동 가나다라마바사 내용내용 내용..............',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 17,
    nickname: '홍길동',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents:
      '길동길동 홍길동 고길동 가나다라마바사 내용내용 내용..............',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 19,
    nickname: '홍길동',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents:
      '길동길동 홍길동 고길동 가나다라마바사 내용내용 내용..............',
    commentCreateDate: '2022-07-29',
  },
  {
    userCode: 20,
    nickname: '홍길동',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    commentContents:
      '길동길동 홍길동 고길동 가나다라마바사 내용내용 내용..............',
    commentCreateDate: '2022-07-29',
  },
];
