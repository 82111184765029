export const mockUser = [
  {
    kakaoEmail: null,
    kakaoId: 321312453243,
    nickname: '지영준',
    profileImg:
      'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
    userCode: 21,
    userRole: 'ROLE_USER',
    userState: 'N',
  },
];
